import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import ImageGridItemPackage from "../../components/image-grid-item-package"


class WordpressHostingPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="WordPress Hosting Bournemouth" />
        <HeroAlt 
          image="/maintenance.jpg"
          title="WordPress Hosting"
          subtitle="Old Salt is able to provide secure, maintained and ultra-fast WordPress hosting."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>What is hosting?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Web hosting is an online service that makes your website’s content accessible on the internet. It stores all of the code needed to run your website and also all of your images, videos and content.</p>
                <p>At Old Salt, we provide managed WordPress hosting for a lot of our clients. We will manage and maintain your hosting to ensure it’s up-to-date and secure. Whilst also providing regular backups of your website and an SSL certificate.</p>
                <p>We monitor all websites hosted with us to ensure that if they go down, we can get the website back online as soon as possible.</p>
              </div>              
            </div>
          </div>
        </div>

        <Spacer space="4" />

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>How much should I be paying for hosting?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>This really depends on the website and the number of visitors that you have accessing the website. We provide a number of packages depending on your requirements. We also have the ability to scale up your hosting should the number of visitors to your site increases.</p>
                <p>See below for a list of hosting packages that we provide.</p>
                <Spacer space="3" />    
              </div>              
            </div>
          </div>
        </div>

        <WorkGrid>
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Hosting Package" title="Basic" price="£20/month" text="<ul><li>Secure & Fast Managed Hosting</li><li>SSL Certificate</li><li>Nightly Backups</li></ul>" link="/contact" />
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Hosting Package" title="Premium" price="£40/month" text="<ul><li>Secure & Fast Managed Hosting</li><li>SSL Certificate</li><li>WordPress Core Updates</li><li>PHP Updates</li><li>Nightly Backups</li><li>Site migration</li></ul>" link="/contact" />
          <ImageGridItemPackage width={4} image={ '/package-bg.jpg' } subtitle="Hosting Package" title="Bespoke" price="On Request" text="<p>Bespoke hosting for your WordPress hosting requirements</p>" link="/contact" />
        </WorkGrid>

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>Find out how we can securely host and maintain your WordPress website.</p>
              <Spacer space="2" />
              <ContactForm pageName="wordpress-hosting" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/maintenance.jpg' } subtitle="Development" title="WordPress Maintenance" link="/services/wordpress-maintenance" />
          <ImageGridItemService width={6} image={ '/security.jpg' } subtitle="Development" title="WordPress Security" link="/services/wordpress-security" />
        </WorkGrid>
 
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WordpressHostingPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`